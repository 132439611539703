/* Buttons */
.button {
  border: 1px solid var(--color-neutral-400);
  border-right: 2px solid var(--color-neutral-600);
  border-bottom: 2px solid var(--color-neutral-600);
  cursor: pointer;
  background: rgba(255, 255, 255, 0.8);

  padding: 12px 15px;
  color: var(--color-neutral-900);
  -webkit-appearance: button;
  cursor: pointer;
  text-align: center;

  width: 100%;
  min-height: 48px;
  display: flex;
}

.button--bg-black,
.button--bg-gray,
.button--bg-white {
  border: 1px solid transparent;
  width: 20px;
  height: 48px;
  margin: 0;
}
.button--bg-black {
  background: black;
}
.button--bg-gray {
  background: gray;
}
.button--bg-white {
  border: 1px solid var(--color-neutral-300);
  background: white;
}

.button__method {
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  margin: auto;
}

.button--modal {
  font-size: 13px;
  text-transform: uppercase;
}

@media (max-width: 600px) {
  .button {
    padding: 8px 10px;
  }
  .button__method {
    font-size: 11px;
  }
  .button__description {
    display: none;
  }
}

.button:hover {
  background: var(--color-neutral-100);
}

.button:focus,
.button:active {
  outline: 1px solid var(--color-neutral-800);
}
