/* Modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-neutral-400);
  z-index: 2;
}
.modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: 1px solid var(--color-neutral-600);
  border-right: 2px solid var(--color-neutral-text);
  border-bottom: 2px solid var(--color-neutral-text);
  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 0;
  outline: none;
  padding: 50px;
  z-index: 3;
  top: 10vh;
  bottom: 10vh;
  max-width: 600px;
  margin: auto;
}

/* Modal content */
.modal__header {
  display: grid;
  grid-template-columns: 300px 1fr 72px;
  grid-template-rows: auto;
  grid-template-areas:
    "title . close"
    "by-line . .";
  margin-bottom: 30px;
}

.button--close-modal {
  grid-area: close;
  grid-row: 1 / 3;
  font-size: 13px;
  text-transform: uppercase;
}
