/* Work area backgrounds */
.bg--black {
  background: #212121;
}
.bg--gray {
  background: #bbbbbb;
}
.bg--white {
  background: white;
}
