/* App */
.app {
  color: var(--color-neutral-text);
  display: grid;
  grid-template-columns: 1fr minmax(var(--min-width), var(--max-width)) 1fr;
  grid-template-rows: min-content min-content auto;
  grid-template-areas:
    "header header header"
    "methods methods methods"
    ". main .";
}
