:root {
  --max-width: 1158px;
  --min-width: 600px;
  --color-neutral-100: rgba(51, 44, 42, 0.1);
  --color-neutral-200: rgba(51, 44, 42, 0.2);
  --color-neutral-300: rgba(51, 44, 42, 0.3);
  --color-neutral-400: rgba(51, 44, 42, 0.4);
  --color-neutral-600: rgba(51, 44, 42, 0.6);
  --color-neutral-700: rgba(51, 44, 42, 0.6);
  --color-neutral-800: rgba(51, 44, 42, 0.8);
  --color-neutral-900: rgba(51, 44, 42, 0.9);
  --color-neutral-text: rgba(51, 44, 42, 1);
}
