/* Methods */
.methods {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
  padding: 10px 0;
  margin-bottom: 2vw;
  grid-area: methods;
}
.bg--black .methods {
  background: gray;
}
.bg--gray .methods {
  background: lightgray;
}
.bg--white .methods {
  background: white;
}

.bg--black .horizontal-color-grid__caption {
  color: rgba(255, 255, 255, 0.8);
}
.bg--gray .horizontal-color-grid__caption {
  color: var(--color-neutral-text);
}
.bg--white .horizontal-color-grid__caption {
  color: var(--color-neutral-text);
}

.methods__content {
  padding: 0 20px;
  max-width: var(--max-width);
  margin: auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 10px;
}
