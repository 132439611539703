/* Color Scales */
.color-grid {
  width: 100%;
  position: relative;
  cursor: pointer;
}

.horizontal-color-grid {
  position: relative;
}
.gradient-color-grid {
  position: relative;
}
.mosaic-color-grid {
  position: relative;
}

.square-color-grid-svg {
  vertical-align: top;
}
.gradient-color-grid-svg {
  vertical-align: top;
}
.mosaic-color-grid-svg {
  vertical-align: top;
}

// Wrapper link
.wrapper-link {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.wrapper-link--full-width {
  grid-column: 1 / span 2;
}

.wrapper-link:focus {
  outline: 2px solid var(--color-neutral-900);
}
