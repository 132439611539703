/* Sections */
.section {
  display: grid;
  margin: 100px 0 50px 0;
}
.section:first-of-type {
  margin-top: 0;
}
.section:last-of-type {
  margin-bottom: 100px;
}
.section--square-scales {
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 32px;
}
.section--horiztontal-scales {
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 40px;
}
.section--gradient-scales {
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 32px;
}
.section--three-mosaic-scales {
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 32px;
}
