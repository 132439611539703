/* Header */
.header {
  padding: 20px 20px;
  border-bottom: 1px solid var(--color-neutral-100);
  background: white;
  grid-area: header;
}

.header__content {
  margin: auto;
  display: grid;
  grid-template-columns: 300px 1fr calc(32px * 3 + 10px) 124px;
  grid-template-rows: auto;
  grid-template-areas:
    "title . background button-help"
    "by-line . . button-help";
}

.title {
  grid-area: title;
}
.name {
  grid-area: by-line;
}
.background-selector {
  grid-area: background;
}
.button--modal {
  grid-area: button-help;
}

// Title and name
.title {
  font-size: 20px;
}
.name {
  font-size: 14px;
  color: var(--color-neutral-600);
  margin: 0;
}

/* Background Selector */
.background-selector {
  display: flex;
  height: 32px;
}
