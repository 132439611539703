/* Save indicator */
.save-indicator {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 20px 8px 8px 8px;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    var(--color-neutral-800) 80%
  );
  text-align: right;
}

.save-indicator--visible {
  display: block;
}
